html,
body {
    margin: 0;
    padding: 0;
}

body {
    height: 100vh;
}

* {
    box-sizing: border-box;
}
